import { App } from "vue";
import { $array } from "@/assets/js/alga.js";

/**
 * @description service to call HTTP request via Axios
 *
 *
 */
class PaginationUtill {
  public static getElecColumn: any = [
    { name: "Location Id", key: "siteid", sortable: true },
    { name: "Detail Id ", key: "electdetailsid", sortable: true },
    { name: "Energy Supplier", key: "suppliername", sortable: true },
    { name: "site ref", key: "siteref", sortable: true },
    { name: "Address", key: "billingstreet", sortable: true },
    { name: "city", key: "billingcity", sortable: true },
    { name: "country", key: "billingcountry", sortable: true },
    { name: "post code", key: "billingpostcode", sortable: true },
    { name: "Meter Serial Number", key: "msn", sortable: true },
  ];

  public static getGasColumn: any = [
    { name: "location Id", key: "siteid", sortable: true },
    { name: "Detail Id", key: "gasdetailsid", sortable: true },
    { name: "Energy Supplier", key: "suppliername", sortable: true },
    { name: "site ref", key: "siteref", sortable: true },
    { name: "Address", key: "billingstreet", sortable: true },
    { name: "City", key: "billingcity", sortable: true },
    { name: "Country", key: "billingcountry", sortable: true },
    { name: "post code", key: "billingpostcode", sortable: true },
    { name: "Meter Serial Number", key: "msn", sortable: true },
  ];

  public static assetAdditionElectrcity: any = [
    { name: "Customer Id", key: "accountid", sortable: true },
    { name: "Location Id", key: "SiteID", sortable: true },
    { name: "Detail Id", key: "electdetailsid", sortable: true },
    { name: "site ref", key: "siteref", sortable: true },
    { name: "Address", key: "billingstreet", sortable: true },
    { name: "city", key: "billingcity", sortable: true },
    { name: "country", key: "billingcountry", sortable: true },
    { name: "post code", key: "billingpostcode", sortable: true },
    { name: "supplier name", key: "suppliername", sortable: true },
    { name: "Meter Serial Number", key: "msn", sortable: true },
    { name: "Addition Date", key: "startdate", sortable: true },
  ];

  public static assetAdditionGas: any = [
    { name: "Customer Id", key: "accountid", sortable: true },
    { name: "Location Id", key: "siteid", sortable: true },
    { name: "Detail Id", key: "gasdetailsid", sortable: true },
    { name: "site ref", key: "siteref", sortable: true },
    { name: "Address", key: "billingstreet", sortable: true },
    { name: "state", key: "billingstate", sortable: true },
    { name: "city", key: "billingcity", sortable: true },
    { name: "country", key: "billingcountry", sortable: true },
    { name: "post code", key: "billingpostcode", sortable: true },
    { name: "Detail Ref", key: "suppliername", sortable: true },
    { name: "Meter Serial Number", key: "msn", sortable: true },
    { name: "Addition Date", key: "startdate", sortable: true },
  ];

  public static assetMeterType: any = [
    // { name: "Customer id", key: "accountid", sortable: true },
    { name: "Loction id", key: "siteid", sortable: true },
    { name: "siteref", key: "siteref", sortable: true },
    // { name: "vattype", key: "vattype", sortable: true },
    { name: "Address", key: "billingstreet", sortable: true },
    { name: "city", key: "billingcity", sortable: true },
    { name: "post code", key: "billingpostcode", sortable: true },
    { name: "state", key: "billingstate", sortable: true },
    { name: "country", key: "billingcountry", sortable: true },
    { name: "supplier", key: "supplier", sortable: true },
    { name: "suppliername", key: "suppliername", sortable: true },
    { name: "utility", key: "utility", sortable: true },
    { name: "detail id", key: "electdetailsid", sortable: true },
    { name: "Meter Serial number", key: "msn", sortable: true },
  ];

  public static LastActualMeterReadElec: any = [
    { name: "location id", key: "locationid", sortable: true },
    { name: "site ref", key: "siteref", sortable: true },
    { name: "address", key: "address", sortable: true },
    { name: "city", key: "city", sortable: true },
    { name: "post code", key: "postcode", sortable: true },
    { name: "detail id", key: "detailid", sortable: true },
    { name: "msn", key: "msn", sortable: true },
    { name: "meter ref", key: "meterref", sortable: true },
    {
      name: "Supplier Name",
      key: "SupplierName",
      sortable: true,
    },
    { name: "day read", key: "dayread", sortable: true },
    { name: "night read", key: "nightread", sortable: true },
    { name: "evening read", key: "eveningread", sortable: true },
    { name: "other read", key: "otherread", sortable: true },
    { name: "peak read", key: "peakread", sortable: true },
    { name: "off peak read", key: "offpeakread", sortable: true },
    { name: "read type", key: "readtype", sortable: true },
    { name: "read date", key: "readdate", sortable: true },
    { name: "report id", key: "reportid", sortable: true },
    { name: "report date", key: "reportdate", sortable: true },
  ];

  public static LastActualMeterReadGas: any = [
    { name: "location id", key: "locationid", sortable: true },
    { name: "site ref", key: "siteref", sortable: true },
    { name: "address", key: "address", sortable: true },
    { name: "city", key: "city", sortable: true },
    { name: "post code", key: "postcode", sortable: true },
    { name: "detail id", key: "detailid", sortable: true },
    {
      name: "meter serial number",
      key: "meterserialnumber",
      sortable: true,
    },
    { name: "meter ref", key: "meterref", sortable: true },
    {
      name: "supplier name",
      key: "suppliername",
      sortable: true,
    },
    { name: "day read", key: "dayread", sortable: true },
    { name: "read type", key: "readtype", sortable: true },
    { name: "read date", key: "readdate", sortable: true },
    { name: "report id", key: "reportid", sortable: true },
    { name: "report date", key: "reportdate", sortable: true },
  ];

  public static LastBilldateElec: any = [
    { name: "location Id", key: "locationid", sortable: true },
    { name: "site ref", key: "siteref", sortable: true },
    { name: "address", key: "address", sortable: true },
    { name: "city", key: "city", sortable: true },
    { name: "post code", key: "postcode", sortable: true },
    { name: "detail Id", key: "detailid", sortable: true },
    {
      name: "meter serial number",
      key: "meterserialnumber",
      sortable: true,
    },
    { name: "meter ref", key: "meterref", sortable: true },
    {
      name: "supplier name",
      key: "suppliername",
      sortable: true,
    },
    { name: "day read", key: "dayread", sortable: true },
    { name: "night read", key: "nightread", sortable: true },
    { name: "evening read", key: "eveningread", sortable: true },
    { name: "peak read", key: "peakread", sortable: true },
    { name: "other read", key: "otherread", sortable: true },
    { name: "read type", key: "readtype", sortable: true },
    { name: "read date", key: "readdate", sortable: true },
    { name: "report id", key: "reportid", sortable: true },
    { name: "report date", key: "reportdate", sortable: true },
  ];

  public static LastBillDateGas: any = [
    { name: "location id", key: "locationid", sortable: true },
    { name: "site ref", key: "siteref", sortable: true },
    { name: "address", key: "address", sortable: true },
    { name: "city", key: "city", sortable: true },
    { name: "post code", key: "postcode", sortable: true },
    { name: "detail id", key: "detailid", sortable: true },
    {
      name: "meter serial number",
      key: "meterserialnumber",
      sortable: true,
    },
    { name: "meter ref", key: "meterref", sortable: true },
    {
      name: "supplier name",
      key: "suppliername",
      sortable: true,
    },
    { name: "day read", key: "dayread", sortable: true },
    { name: "read type", key: "readtype", sortable: true },
    { name: "read date", key: "readdate", sortable: true },
    { name: "report id", key: "reportid", sortable: true },
    { name: "report date", key: "reportdate", sortable: true },
  ];

  public static AccuralReportElec: any = [
    { name: "Location Id", key: "siteid", sortable: true },
    { name: "detail ref", key: "detail", sortable: true },
    { name: "client ref", key: "clientref", sortable: true },
    { name: "Detail id", key: "accountid", sortable: true },
    { name: "KWH Billed", key: "KWHBilled", sortable: true },
    { name: "per day Amount", key: "Perdayamout", sortable: true },
    { name: "per day Consumption", key: "PerdayConsumption", sortable: true },
    { name: "MPAN Number", key: "MPAN", sortable: true },
    { name: "Accured Days", key: "accureddays", sortable: true },
    { name: "total excl Vat(£)", key: "totexclvat", sortable: true },
    { name: "GapDayConsumption", key: "GapDayConsumption", sortable: true },
  ];

  public static AccuralReportgas: any = [
    { name: "Location Id", key: "siteid", sortable: true },
    { name: "detail ref", key: "detail", sortable: true },
    { name: "client ref", key: "clientref", sortable: true },
    { name: "Detail id", key: "accountid", sortable: true },
    { name: "KWH Billed", key: "KWHBilled", sortable: true },
    { name: "per day Amount", key: "Perdayamout", sortable: true },
    { name: "per day Consumption", key: "PerdayConsumption", sortable: true },
    { name: "Meter Serial Number", key: "msn", sortable: true },
    { name: "Accured Tax", key: "accureddays", sortable: true },
    { name: "total excl Vat(£)", key: "totexclvat", sortable: true },
    { name: "GapDayConsumption", key: "GapDayConsumption", sortable: true },
  ];

  public static MeterTypeReport: any = [
    // { name: "Customer id", key: "accountid", sortable: true },
    { name: "Loction id", key: "siteid", sortable: true },
    { name: "siteref", key: "siteref", sortable: true },
    // { name: "vattype", key: "vattype", sortable: true },
    { name: "Address", key: "billingstreet", sortable: true },
    { name: "city", key: "billingcity", sortable: true },
    { name: "post code", key: "billingpostcode", sortable: true },
    { name: "state", key: "billingstate", sortable: true },
    { name: "country", key: "billingcountry", sortable: true },
    { name: "supplier", key: "supplier", sortable: true },
    { name: "suppliername", key: "suppliername", sortable: true },
    { name: "utility", key: "utility", sortable: true },
    { name: "detail id", key: "electdetailsid", sortable: true },
    { name: "Meter Serial number", key: "msn", sortable: true },
  ];

  public static PositiondataTableColNames:any = [
    { name: "Month ", key: "month", sortable: true },
    { name: "Total MW ", key: "total_mw", sortable: true },
    { name: "Fixed MW ", key: "fixed_mw", sortable: true },
    { name: "Floating MW ", key: "floating_mw", sortable: true },
    { name: "% Fixed ", key: "fixed_per", sortable: true },
    { name: "Baseline £/MWh ", key: "baseline", sortable: true },
    { name: "Budget £/MWh ", key: "budget", sortable: true },
    { name: "Traded Price £/MWh ", key: "traded", sortable: true },
    { name: "Market Price £/MWh ", key: "market", sortable: true },
    { name: "MTM £/MWh ", key: "mtm", sortable: true },
    { name: "MTM Cost ", key: "mtm_cost", sortable: true },
    { name: "Savings V Baseline ", key: "savings_baseline", sortable: true },
    { name: "Savings V Budget ", key: "savings", sortable: true },
    { name: "Forecast Volume (KWh@NBP) ", key: "forecast_vol", sortable: true },
    { name: "Forecast Energy Cost (£)", key: "forecast_energy_cost", sortable: true },
    { name: "Actual Volume (kWh@CT) ", key: "actual_vol", sortable: true },
    { name: "Actual Energy Cost", key: "actual_energy_cost", sortable: true },
    { name: "Volume Saving ", key: "vol_savings", sortable: true },
    { name: "Savings v Current Budget (£) ", key: "savings_cbudget12", sortable: true },
    { name: "Savings v Original Budget (£)", key: "savings_obudget12", sortable: true },
    { name: "Baseline (p/kWh@NBP) ", key: "baseline_cost", sortable: true },
    { name: "Budget inc CaR (p/KWh@NBP)  ", key: "budget_cost_nr", sortable: true },
  ];

  public static PositiondataTableGasColNames:any = [
    { name: "Month ", key: "month", sortable: true },
    { name: "Total th/day ", key: "total_mw", sortable: true },
    { name: "Fixed th/day ", key: "fixed_mw", sortable: true },
    { name: "Floating th/day ", key: "floating_mw", sortable: true },
    { name: "% Fixed ", key: "fixed_per", sortable: true },
    { name: "Baseline p/th ", key: "baseline", sortable: true },
    { name: "Budget p/th ", key: "budget", sortable: true },
    { name: "Traded Price p/th ", key: "traded", sortable: true },
    { name: "Market Price p/th ", key: "market", sortable: true },
    { name: "MTM p/th ", key: "mtm", sortable: true },
    { name: "MTM Cost ", key: "mtm_cost", sortable: true },
    { name: "Savings V Baseline ", key: "savings_baseline", sortable: true },
    { name: "Savings V Budget ", key: "savings", sortable: true },
  ];

  public static showPagination(allPages, currentPage) {
    console.log("inside method");
    $array.pagination(allPages, currentPage, 1);
  }
}

export default PaginationUtill;
